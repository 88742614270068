<style scoped>
/* .container {
  margin-top: 200px;
} */
.container >>> .el-card__header {
  border: none;
}
.container .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.container .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  /* top: 50px; */
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
}
.card-content {
  width: 100%;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  /* justify-content: space-around; */
}
.my-card {
  border-radius: 10px;
  border: solid 1px #3798ee;
  margin-right: 10px;
}
.dialog >>> .el-dialog__body {
  padding: 30px 35px 35px 40px;
}

.active {
  border: solid 1px #3798ee;
}
.report-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-title .title {
  font-size: 20px;
  /* line-height: 29px; */
  color: #333333;
  font-weight: 600;
}
.report-title .collect {
  color: #939393;
  font-size: 16px;
  cursor: pointer;
}
.line >>> .el-descriptions__title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #7baee3;
  vertical-align: -3px;
  margin-right: 10px;
}
</style>
<template>
  <div>
    <el-card id="container" class="container" style="height: auto">
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回首页
        </el-button>
        <p class="card-title">我的报告</p>
      </div>
      <el-empty
        v-if="isShow"
        style="margin-bottom: 20px"
        :image="emptyImg"
        :image-size="400"
      >
        <template slot="description">
          <span style="color: #999"> 没有查询到相应内容 </span>
        </template>
      </el-empty>
      <div v-else style="padding-left: 100px">
        <el-row :gutter="10" class="card-content" type="flex">
          <el-col
            :span="7"
            v-for="item in list"
            :key="item.id"
            style="margin-right: 10px; cursor: pointer"
          >
            <my-card
              :list="item"
              :class="id == item.id ? 'active' : ''"
              @click.native="bandleActive(item.id)"
              :active="id == item.id"
            ></my-card>
          </el-col>
        </el-row>
        <div class="card-bottom">
          <el-pagination
            class="pagination"
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="getList"
            :page-size="size"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!--详情弹窗-->
    <el-dialog :visible.sync="dialogTableVisible" class="dialog">
      <div class="dialog-title" slot="title">报告详情</div>
      <div class="report-title">
        <div class="title"></div>
        <div class="collect" @click="bindCollect" v-if="is_favourite == 0">
          <i class="iconfont icon-xin"></i>
          <span style="margin-left: 10px">收藏报告</span>
        </div>
        <div class="collect" @click="bindCollect" style="color: #ff4e00" v-else>
          <i class="iconfont icon-taoxin"></i>
          <span style="margin-left: 10px">取消收藏</span>
        </div>
      </div>
      <!-- <el-descriptions :column="2" style="margin-top: 20px">
        <el-descriptions-item label="样本编号" labelStyle="width:70px;">{{
          detailList.sample_number
        }}</el-descriptions-item>
        <el-descriptions-item label="样本型号">{{
          detailList.patient_project_type
        }}</el-descriptions-item>
      </el-descriptions> -->
      <el-descriptions
        :column="2"
        title="受检者信息"
        class="line"
        :labelStyle="{ width: '150px' }"
      >
        <el-descriptions-item
          label="动物种类"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.animal_type }}</el-descriptions-item
        >

        <el-descriptions-item
          label="联系人"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.patient_name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="姓名"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="动物名称"
          v-if="detailList.patient_project_name == 'AID'"
        >
          {{ detailList.animal_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="联系人手机号"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.patient_phone }}</el-descriptions-item
        >
        <el-descriptions-item
          label="手机号码"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_phone }}</el-descriptions-item
        >
        <el-descriptions-item
          label="性别"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_sex }}</el-descriptions-item
        >
        <el-descriptions-item
          label="证件类型"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_id_type }}</el-descriptions-item
        >
        <el-descriptions-item
          label="年龄"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_age }}</el-descriptions-item
        >
        <el-descriptions-item
          label="证件号码"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_id_card }}</el-descriptions-item
        >
        <el-descriptions-item label="送检医生/单位/科室">
          {{ detailList.patient_doctor_department }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="1"
        title="样本信息"
        class="line"
        :labelStyle="{ width: '150px' }"
      >
        <el-descriptions-item label="样本编号">{{
          detailList.sample_number
        }}</el-descriptions-item>
        <el-descriptions-item label="样本类型">{{
          detailList.patient_project_type
        }}</el-descriptions-item>
        <el-descriptions-item label="采样时间">{{
          detailList.sampling_date
        }}</el-descriptions-item>
                <el-descriptions-item label="收样时间">{{
          detailList.receiving_date
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="3"
        title="检测信息"
        class="line"
        style="margin-top: 20px"
      >
        <el-descriptions-item label="检测时间">{{
          detailList.start_time
        }}</el-descriptions-item>
        <el-descriptions-item label="检测项目">{{
          detailList.patient_project_item
        }}</el-descriptions-item>
        <el-descriptions-item label="检测单位">
          {{ detailList.organ }}
        </el-descriptions-item>
        <el-descriptions-item label="检测地点">{{
          detailList.location
        }}</el-descriptions-item>
        <el-descriptions-item label="检测方法">{{
          detailList.test_method
        }}</el-descriptions-item>
        <el-descriptions-item label="检测设备">{{
          detailList.test_equipment
        }}</el-descriptions-item>
        <el-descriptions-item label="设备编号">{{
          detailList.device_id
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="1"
        class="line"
        title="检测结果"
        style="margin-top: 20px"
      >
        <el-descriptions-item label="报告编号">{{
          detailList.report_number
        }}</el-descriptions-item>
        <el-descriptions-item label="检测结果">
            <div style="white-space:pre;">
            {{newResult}}
            </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-button
        type="primary"
        size="big"
        @click="daochu"
        :icon="isDownLoad ? 'el-icon-loading' : 'el-icon-download'"
        >下载报告</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import MyCard from "@/components/MyCard/MyCard.vue";
import {
  getPatientList,
  getPatientDetail,
  Addfavourite,
  Deletefavourite,
  patientExport,
} from "@/api/base";

export default {
  components: { MyCard },
  name: "report",
  data() {
    return {
      emptyImg: require("@/assets/images/empty.png"),
      isShow: false,
      total: null,
      dialogTableVisible: false,
      isDownLoad: false,
      current: null,
      list: [],
      id: null,
      size: 6,
      is_favourite: null, //收藏状态
      detailList: {
        patient_project_item: null,
        patient_project_type: null,
        sample_number: null,
        patient_project_name: null,
        animal_type: null,
        patient_name: null,
        animal_name: null,
        patient_phone: null,
        patient_doctor_department: null,
        sampling_date: null,
        organ: null,
        startTime: null,
        location: null,
        deviceID: null,
        patient_project_item: null,
        test_method: null,
        patient_result: null,
      },
    };
  },
  component: {
    MyCard,
  },
  watch:{
     detailList(){
         this.newPatientResult
     }
  },
  created() {
    this.getList();
  },
  computed:{
    newPatientResult(){
         let patient_result = this.detailList.patient_result
         let arr = patient_result.split("|")
         let newResult = ''
         arr.forEach(item => {
            newResult += (item + "\n")
         });
         this.newResult = newResult
     }
  },
  methods: {
    //获取报告列表
    async getList(page = 1) {
      let current = page;
      let res = await getPatientList({
        size: 6,
        current,
      });
      if (res.code != 1) return this.$message.error(res.message);
      this.current = res.pageIndex + 1;
      this.list = res.data?res.data.records:[];
      this.total = res.data?res.dataCount:0;
      if (this.list.length != 0) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    //返回首页
    backHome() {
      this.$router.back();
    },
    //点击展开详情
    async bandleActive(row) {
      this.id = row;
      this.dialogTableVisible = true;
      const res = await getPatientDetail(this.id);
      if (res.code != 1) return this.$message.error(res.message);
      this.detailList = res.dataSingle;
      this.detailList.patient_id_card = "**************" + this.detailList.patient_id_card.substr(-4)
      this.is_favourite = res.dataSingle.is_favourite;
    },
    //收藏/取消收藏
    async bindCollect() {
      //detailList.is_favourite 0未收藏  1已收藏
      let uuid = this.detailList.uuid;
      if (this.is_favourite == 0) {
        let res = await Addfavourite({ uuid });
        this.is_favourite = 1;
        if (res.code == 1) return this.$message.success("收藏成功");
      } else {
        this.is_favourite = 0;
        let res = await Deletefavourite({ uuid });
        if (res.code == 1) return this.$message.success("取消收藏成功");
      }
    },
    //导出pdf
    async daochu() {
      this.isDownLoad = true;
      let id = this.detailList.id;
      const res = await patientExport(id);
      if (res) {
        const blob = new Blob([res], {
          type: "application/pdf",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${this.detailList.sample_number}.pdf`);
        link.click();
        link = null;
      } else {
        this.$message.error("导出失败");
      }
      this.isDownLoad = false;
    },
  },
};
</script>
